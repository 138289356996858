class API {

  constructor() {
    this.baseUrl = '/api/'
    this.sessionToken = ''
  }

  login(email, password) {
    return this.call('admin/login', 'POST', {
      email,
      password,
    })
  }

  loadPatients() {
    return this.call('admin/reports')
  }

  loadGlucoseReports(userId) {
    return this.call(`admin/reports/${userId}/glucose`)
  }

  loadGlucoseDailyChart(userId, day) {
    return this.call(`admin/reports/${userId}/glucose/${ day }`)
  }

  loadSleepReport(userId) {
    return this.call(`admin/reports/${userId}/sleep`)
  }

  loadStepsReport(userId) {
    return this.call(`admin/reports/${userId}/steps`)
  }

  loadBodyFatReport(userId) {
    return this.call(`admin/reports/${userId}/body-fat`)
  }

  loadHRVReport(userId) {
    return this.call(`admin/reports/${userId}/hrv`)
  }

  loadBMIReport(userId) {
    return this.call(`admin/reports/${userId}/bmi`)
  }

  // loadBodyMassReport(userId) {
  //   return this.call(`admin/reports/${userId}/weight/all`)
  // }

  // loadHeightReport(userId) {
  //   return this.call(`admin/reports/${userId}/height/all`)
  // }

  logout() {
    this.sessionToken = ''
  }

  call(path, method = 'GET', data) {
    return new Promise((resolve) => {
      const { sessionToken } = this
      const endpoint = `${ this.baseUrl }${ path }`
      const xhr = new XMLHttpRequest()
      xhr.open(method, endpoint)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          const responseData = JSON.parse(xhr.responseText)
          if ('token' in responseData) {
            const { token: newSessionToken } = responseData
            this.sessionToken = newSessionToken
          }
          resolve(responseData)
        }
      }
      if (sessionToken.length) {
        xhr.setRequestHeader('Authorization', sessionToken)
      }
      const payload = data ? JSON.stringify(data) : null
      // console.log('-------------- SENDING');
      // console.log(payload);
      // console.log('--------------');
      xhr.send(payload)
    })
  }

}

export default new API
