import React, { useState } from 'react';
import SimpleAccordion from './Accordion';
import Accordion from './Accordion';
import API from '../core/API'

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      patients: null,
      activePatient: null,
      glucoseReports: null,
      glucoseDaily: null,
      glucoseDailyDrops: null,
      glucoseDailySpikes: null,
      sleepReport: null,
      stepsReport: null,
      bodyFatReport: null,
      hrvReport: null,
      bmiReport: null,
      heightReport: null,
      bodyMassReport: null,
    }
  }
  componentDidMount() {
    this.loadPatients()
  }

  async loadPatients() {
    const response = await API.loadPatients()
    if ('data' in response) {
      this.setState({
        patients: response.data,
      })
      this.selectPatient(response.data[0])
    }
  }
  async loadBodyMass(id) {
    const response = await API.loadBodyMassReport(id)
    if ('data' in response) {
      this.setState({
        bodyMassReport: response.data.map(item => ({
          time: (new Date(item.time)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: `${ item.value } kg`,
        })),
      })
    }
  }
  formatSleepMinutes(minutes) {
    const hours = Math.floor(minutes / 60)
    const remaining = minutes - (hours * 60)
    return `${ hours } hours ${ remaining } minutes`
  }
  async loadSleep(id) {
    const response = await API.loadSleepReport(id)
    if ('data' in response) {
      this.setState({
        sleepReport: response.data.map(item => ({
          time: (new Date(item.day)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: this.formatSleepMinutes(item.value),
        })),
      })
    }
  }
  async loadSteps(id) {
    const response = await API.loadStepsReport(id)
    if ('data' in response) {
      this.setState({
        stepsReport: response.data.map(item => ({
          time: (new Date(item.day)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: item.value,
        })),
      })
    }
  }
  async loadBodyFat(id) {
    const response = await API.loadBodyFatReport(id)
    if ('data' in response) {
      this.setState({
        bodyFatReport: response.data.map(item => ({
          time: (new Date(item.day)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: `${ item.value } %`,
        })),
      })
    }
  }
  async loadHRV(id) {
    const response = await API.loadHRVReport(id)
    if ('data' in response) {
      this.setState({
        hrvReport: response.data.map(item => ({
          time: (new Date(item.day)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: `${ item.value } ms`,
        })),
      })
    }
  }
  async loadBMI(id) {
    const response = await API.loadBMIReport(id)
    if ('data' in response) {
      this.setState({
        bmiReport: response.data.map(item => ({
          time: (new Date(item.day)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: `${ item.value } BMI`,
        })),
      })
    }
  }
  async loadHeight(id) {
    const response = await API.loadHeightReport(id)
    if ('data' in response) {
      this.setState({
        heightReport: response.data.map(item => ({
          time: (new Date(item.time)).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'}),
          value: `${ item.value } cm`,
        })),
      })
    }
  }
  async loadGlucoseReports(id) {
    const response = await API.loadGlucoseReports(id)
    if ('data' in response && response.data !== null) {
      this.setState({
        glucoseDaily: null,
        glucoseDailyDrops: null,
        glucoseDailySpikes: null,
        glucoseReports: Object.entries(response.data).map(([ key, value ]) => ({
          value: key,
          label: `${ key } (${ value })`
        }))
      }, () => {
        this.loadGlucoseDaily(Object.entries(response.data)[0][0])
      })
    }
  }
  async loadGlucoseDaily(day) {
    const { activePatient } = this.state
    const response = await API.loadGlucoseDailyChart(activePatient.id, day)
    if ('data' in response) {
      this.setState({
        glucoseDaily: response.data,
        glucoseDailyDrops: response.drops,
        glucoseDailySpikes: response.spikes,
      })
    }
  }
  selectPatient = (activePatient) => {
    this.setState({
      activePatient,
      glucoseReports: null,
      glucoseDaily: null,
      glucoseDailyDrops: null,
      glucoseDailySpikes: null,
      sleepReport: null,
      stepsReport: null,
      bodyFatReport: null,
      hrvReport: null,
      bmiReport: null,
      bodyMassReport: null,
      heightReport: null,
    }, () => {
      // this.loadBodyMass(activePatient.id)
      // this.loadHeight(activePatient.id)
      this.loadSleep(activePatient.id)
      this.loadGlucoseReports(activePatient.id)
      this.loadSteps(activePatient.id)
      this.loadBodyFat(activePatient.id)
      this.loadHRV(activePatient.id)
      this.loadBMI(activePatient.id)
    })
  }

  onFilterChange = (day) => {
    this.loadGlucoseDaily(day)
  }

  onLogout = () => {
    API.logout()
    this.props.onLogout()
  }
  render() {
    const { patients, activePatient, glucoseReports, glucoseDaily, glucoseDailyDrops, glucoseDailySpikes, sleepReport, stepsReport, hrvReport, bmiReport, bodyFatReport, bodyMassReport, heightReport } = this.state
    return (
      <div className="mt-10">
        <header className="bg-transparent h-11 text-white py-10.5 mb-10 flex justify-between items-center">
          <div className="text-lg font-bold">
            <img alt="intend logo" src="./assets/logo.svg" className="h-10 w-auto" />
          </div>
          <div>
            {/* <button className="bg-violet-950 text-white h-[44px] px-4 py-4.5 text-sm rounded-3xl mr-4 hover:bg-white hover:outline hover:outline-2 hover:outline-bg-violet-950 hover:text-violet-950 hover:transition hover:transition-colors hover:transition-duration-150 hover:transition-timing-function-ease-out">
              Add Patient
            </button> */}
            <button className="bg-violet-950 text-white h-[44px] px-4 py-4.5 text-sm rounded-3xl hover:bg-white hover:outline hover:outline-2 hover:outline-bg-violet-950 hover:text-violet-950 hover:transition hover:transition-colors hover:transition-duration-150 hover:transition-timing-function-ease-out" onClick={ this.onLogout }>
              Logout
            </button>
          </div>
        </header>
        <div className="flex gap-10">
          <aside className="w-1/3">
            {patients && patients.map((data) => (
              <div
                key={data.id}
                className={
                  (activePatient.id === data.id
                    ? 'text-white bg-violet-950'
                    : 'bg-white text-purple-950') +
                  ' rounded-2xl p-4 mb-4 shadow-[0px_-4px_14px_0px_#2524291A] cursor-pointer'
                }
                onClick={ () => this.selectPatient(data) }
              >
                <h3 className="text-sm font-bold">{data.fullName}</h3>
                <p className="text-teal-500 font-bold text-sm">
                  {data.sex} {data.age}
                </p>
              </div>
            ))}
          </aside>
          {activePatient !== null && <main className="w-2/3 flex flex-col gap-4">
            <h2 className="text-2xl font-semibold text-purple-950">
              {activePatient.fullName}
            </h2>
            <div>
              <div className="flex flex-col gap-2 mt-4">
                {glucoseReports !== null && glucoseDaily !== null && (
                  <Accordion label='Glucose' showDatePicker={ false } filter={ glucoseReports } onFilterChange={ this.onFilterChange } chartData={ glucoseDaily } chartSpikes={ glucoseDailySpikes } chartDrops={ glucoseDailyDrops } />
                )}
                {sleepReport !== null && sleepReport.length > 0 && (
                  <Accordion label='Sleep' isExpanded={ false } showDatePicker={ false } items={ sleepReport } />
                )}
                {stepsReport !== null && stepsReport.length > 0 && (
                  <Accordion label='Steps' isExpanded={ false } showDatePicker={ false } items={ stepsReport } />
                )}
                {bodyFatReport !== null && bodyFatReport.length > 0 && (
                  <Accordion label='Body Fat' isExpanded={ false } showDatePicker={ false } items={ bodyFatReport } />
                )}
                {hrvReport !== null && hrvReport.length > 0 && (
                  <Accordion label='Heart Rate Variability (HRV)' isExpanded={ false } showDatePicker={ false } items={ hrvReport } />
                )}
                {bmiReport !== null && bmiReport.length > 0 && (
                  <Accordion label='BMI' isExpanded={ false } showDatePicker={ false } items={ bmiReport } />
                )}
                {/* {heightReport !== null && heightReport.length > 0 && (
                  <Accordion label='Height' isExpanded={ false } showDatePicker={ false } items={ heightReport } />
                )}
                {bodyMassReport !== null && bodyMassReport.length > 0 && (
                  <Accordion label='Body Mass' showDatePicker={ false } items={ bodyMassReport } />
                )} */}
                {/* {PARAMETERS.map((parameter) => (
                  <Accordion
                    label='Sugar'
                    children={parameter.details}
                    key={parameter.id}
                  />
                ))} */}
              </div>
            </div>
          </main>}
        </div>
      </div>
    );
  }
}

const FAKE_DATA = [
  {
    id: 1,
    name: 'John Doe',
    description: 'Male, 36',
  },
  {
    id: 2,
    name: 'Natchan Beck',
    description: 'Male, 29',
  },
  {
    id: 3,
    name: 'Bobby Johnson',
    description: 'Male, 40',
  },
  {
    id: 4,
    name: 'Ben Ericsson',
    description: 'Male, 19',
  },
  {
    id: 5,
    name: 'Natalie Black',
    description: 'Female, 24',
  },
  {
    id: 6,
    name: 'Susan White',
    description: 'Male, 50',
  },
];

const PARAMETERS = [
  {
    id: 1,
    name: 'Glucose',
    details:
      'Details or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagramDetails or diagram ',
  },
  { id: 2, name: 'Weight', details: 'Details or diagram' },
  { id: 3, name: 'Height', details: 'Details or diagram' },
  { id: 4, name: 'Steps', details: 'Details or diagram' },
  { id: 5, name: 'Heart', details: 'Details or diagram' },
];

export default Admin;
