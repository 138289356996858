import './App.css';
import Admin from './components/Admin';
import Login from './components/Login';
import React from 'react';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
    }
  }

  onLogin = () => {
    this.setState({ isLoggedIn: true })
  }

  onLogout = () => {
    this.setState({ isLoggedIn: false })
  }

  render() {
    const { isLoggedIn } = this.state
    return (
      <div className="container mx-auto">
        {!isLoggedIn && <Login onLogin={ this.onLogin } />}
        {isLoggedIn && <Admin onLogout={ this.onLogout } /> }
      </div>
    )
  }
}

export default App;

//background: #290852; violet-950 - dark violet
//background: #2D1F42; - text purple-950
//background: #24AE9D; teal-500 - green

//shadow-[0px_-4px_14px_0px_#2524291A]
