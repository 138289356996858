import React from 'react'
import API from '../core/API'

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  submit = async () => {
    const { email, password } = this.state
    const data = await API.login(email, password)
    if ('error' in data) {
      alert(data.error)
    } else {
      this.props.onLogin()
    }
  }

  render() {
    const { email, password } = this.state
    return (
      <div className="flex h-screen flex-1 flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="intend logo"
            src="./assets/logo.svg"
            className="mx-auto h-10 w-auto"
          />
          {/* <h2 className="mt-16 text-center text-2xl font-bold leading-9 tracking-tight text-teal-500">
            Sign in to your account
          </h2> */}
        </div>
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-purple-950"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={ email }
                required
                autoComplete="email"
                onChange={ (e) => this.setState({ email: e.target.value }) }
                className="w-full h-[42px] rounded-2xl py-2 text-purple-950 shadow-sm border-none placeholder:text-purple-950 focus:border-none focus:ring-2 focus:ring-inset focus:ring-violet-950 sm:text-sm sm:leading-6 shadow-[1px_-4px_14px_0px_#2524291A]"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={ password }
                required
                autoComplete="current-password"
                onChange={ (e) => this.setState({ password: e.target.value }) }
                className="w-full h-[42px] rounded-2xl text-purple-950 shadow-sm border-none placeholder:text-purple-950 
                hover:border-none hover:ring-2 focus:ring-inset hover:ring-violet-950
                
                  focus:border-none focus:ring-2 focus:ring-inset focus:ring-violet-950 sm:text-sm sm:leading-6 shadow-[1px_-4px_14px_0px_#2524291A]"
              />
            </div>
          </div>
          <div style={{ marginTop: '16px' }}>
            <button
              type="submit"
              className="flex w-full h-[42px] items-center justify-center bg-violet-950 text-white px-4 text-sm rounded-3xl mr-4 hover:bg-white hover:outline hover:outline-2 hover:outline-bg-violet-950 hover:text-violet-950 hover:transition hover:transition-colors hover:transition-duration-150 hover:transition-timing-function-ease-out"
              onClick={ this.submit }
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    );
  }

}

export default Login;
