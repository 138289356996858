import { useState } from 'react';
import BloodGlucoseChart from './BloodGlucoseChart';

const openIcon = (
  <svg
    data-accordion-icon
    className="w-3 h-3 rotate-0 shrink-0"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5 5 1 1 5"
    />
  </svg>
);

const closeIcon = (
  <svg
    data-accordion-icon
    className="w-3 h-3 rotate-180 shrink-0"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5 5 1 1 5"
    />
  </svg>
);

function Accordion({ isExpanded, label, showDatePicker, children, items, filter, onFilterChange, chartData, chartSpikes, chartDrops }) {
  console.log(chartData)
  const [expanded, setExpanded] = useState(isExpanded !== undefined ? isExpanded : true);

  const toggleExpanded = () => setExpanded((current) => !current);

  return (
    <>
      <div className="flex justify-between gap-2">
        <div className="flex justify-start items-center">
          <h3
            className="text-lg text-purple-950 font-semibold cursor-pointer"
            onClick={toggleExpanded}
          >
            {label}
          </h3>
          <span className="self-center ml-2" onClick={toggleExpanded}>
            {expanded ? closeIcon : openIcon}
          </span>
        </div>
        {showDatePicker && (
          <button className="bg-teal-500 text-white px-6 h-8 text-xs rounded-3xl mr-4 hover:bg-white hover:outline hover:outline-2 hover:outline-bg-teal-500 hover:text-teal-500 hover:transition hover:transition-colors hover:transition-duration-150 hover:transition-timing-function-ease-out">
            Date
          </button>
        )}
        {filter !== undefined && (
          <select onChange={ (e) => onFilterChange(e.target.value) } className="bg-teal-500 text-white px-6 h-8 text-xs border-none rounded-3xl mr-4 hover:bg-white hover:outline hover:outline-2 hover:outline-bg-teal-500 hover:text-teal-500 hover:transition hover:transition-colors hover:transition-duration-150 hover:transition-timing-function-ease-out">
            {filter.map(option => (
              <option key={ option.value } value={ option.value }>{ option.label }</option>
            ))}
          </select>
        )}
      </div>
      {expanded && children !== undefined && (
        <div className="w-full h-full text-xs rounded-2xl text-purple-950 shadow-[1px_-4px_14px_0px_#2524291A] bg-white transition duration-1000 ease-in-out px-4 py-3">
          {children}
        </div>
      )}
      {expanded && items !== undefined && items.map((item) => (
          <div key={ item.time } className="w-full h-full flex justify-between text-xs rounded-2xl text-purple-950 shadow-[1px_-4px_14px_0px_#2524291A] bg-white transition duration-1000 ease-in-out px-4 py-3">
            <p>{item.time}</p>
            <p className='font-bold'>{item.value}</p>
          </div>
      ))}
      {expanded && chartData !== undefined && chartSpikes !== undefined && chartDrops !== undefined && (
        <div className="w-full h-full text-xs rounded-2xl text-purple-950 shadow-[1px_-4px_14px_0px_#2524291A] bg-white transition duration-1000 ease-in-out px-4 py-3">
          <BloodGlucoseChart data={ chartData } />
          <div className="w-full h-full flex justify-between text-xs rounded-2xl text-purple-950 bg-white transition duration-1000 ease-in-out px-4 py-3">
            <p>Spikes</p>
            <p className='font-bold'>{chartSpikes}</p>
          </div>
          <div className="w-full h-full flex justify-between text-xs rounded-2xl text-purple-950 bg-white transition duration-1000 ease-in-out px-4 py-3">
            <p>Drops</p>
            <p className='font-bold'>{chartDrops}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Accordion;
